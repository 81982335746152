<template>
  <div id="one-news">
    <navigation
      :index="{ name: 'triotech.front.news.index' }"
      :index-text="$t('actions.show_news_list')"
      :links="news._links"
      :next-text="$t('actions.show_news_next')"
      param="slug"
      :prev-text="$t('actions.show_news_prev')"
      route="triotech.front.news.show"
    />
    <div class="card">
      <div class="card-img">
        <ResponsiveBanners
          :alt="news.cover_image_title"
          css-class="img-fluid"
          :desktop-banner="news.cover_image"
          filter-name="dynamic_page_banner"
          :mobile-banner="news.cover_small_image"
          :src="news.cover_image"
        />
      </div>
      <div class="card-img-overlay">
        <div class="dark-filter"></div>
      </div>
    </div>
    <div class="container py-3 px-4 px-md-0 py-md-3 my-md-5">
      <h1 class="text-center mb-2 pb-0">
        {{ news.label }}
      </h1>
      <p v-if="news.category && news.category.label !== null" class="text-center mb-5 text-primary">
        <i class="ti ti-2x ti-tag pr-2"></i>{{ news.category.label }}<br />
        {{ news.published_at|moment('Do MMM YYYY') }}
      </p>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-html="news.content"></div>
      <div v-if="news.advice && 0 < news.advice.length">
        <div class="alert alert-info" role="alert">
          <strong>{{ $t('actions.advice') }}</strong>
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="advice"
            v-html="news.advice"
          ></div>
        </div>
      </div>
      <div
        v-if="news.employee"
        class="container"
      >
        <employee-description
          :employee="news.employee"
          :is-author="true"
        />
      </div>
    </div>
    <div
      v-if="hasRelatedNews"
      class="container news-section"
    >
      <h2>
        {{ $t('pages.news.related') }}
      </h2>
      <slick
        ref="slick"
        class="px-5"
        :options="slickOptions"
      >
        <div
          v-for="(showNews, idx) in relatedNews"
          :key="idx"
          class="p-4"
        >
          <NewsCard
            :has-three="true"
            :item="showNews"
          />
        </div>
      </slick>
    </div>
    <ask-block />
  </div>
</template>

  <script>
  import AskBlock from '../../components/AskBlock';
  import EmployeeDescription from '../../components/EmployeeDescription';
  import Navigation from '../../components/Navigation';
  import NewsCard from '../../components/NewsCard';
  import ResponsiveBanners from '../../components/ResponsiveBanners';

  export default {
    name: 'NewsShowPage',
    components: {
      AskBlock,
      EmployeeDescription,
      Navigation,
      NewsCard,
      ResponsiveBanners,
    },
    data() {
      return {
        id: {
          type: Number,
          default: 0,
        },
        news: {},
        navigation: {},
        hasRelatedNews: false,
        slickOptions: {
          autoplay: false,
          autoplaySpeed: 2000,
          nextArrow: '<button aria-label="Navigation diaporama" type="button" class="slick-next"><i class="ti ti-2x ti-arrow-right"></i></button>',
          prevArrow: '<button aria-label="Navigation diaporama" type="button" class="slick-prev"><i class="ti ti-2x ti-arrow-left"></i></button>',
          slidesToShow: 3,
          swipeToSlide: true,
          responsive: [{
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          }, {
            breakpoint: 725,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          }],
        },
      };
    },
    computed: {
      relatedNews() {
        return this.news.related.filter(news => news.enabled);
      },
    },
    metaInfo() {
      if (null !== this.news) {
        return {
          title: this.news.meta_title,
          meta: [
            { property: 'og:url', content: this.$config.get('basepath') + window.location.pathname.replace(/\/$/, '') },
            { property: 'og:title', content: this.news.meta_title },
            { property: 'og:description', content: this.news.meta_description },
            { name: 'description', content: this.news.meta_description },
          ],
          link: [
            { rel: 'canonical', href: this.$config.get('basepath') + window.location.pathname.replace(/\/$/, '') },
          ],
        };
      }

      return {};
    },
    async created() {
      let preview = '';

      if (undefined !== this.$route.query.preview && 'true' === this.$route.query.preview) {
        preview = '?preview=true';
      }

      this.news = await this.$ajax
        .get(`public/news/${this.$route.params.slug}${preview}`)
        .catch(() => {
          this.$router.push({ name: 'triotech.front.not_found' });
        });

      this.hasRelatedNews = this.news.related && 0 < this.news.related.length;
    },
    methods: {
      next() {
        this.$refs.slick.next();
      },
      prev() {
        this.$refs.slick.prev();
      },
      reInit() {
        this.$nextTick(this.$refs.slick.reSlick);
      },
    },
  };
</script>
