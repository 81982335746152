<template>
  <div id="home">
    <div class="homepage-header">
      <div class="inner-header row align-items-center">
        <div
          :class="[
            'col-lg-8', {
              'col-md-6': displayContactForm,
              'col-md-12': !displayContactForm,
            },
          ]"
        >
          <p class="catch-title mb-2">
            {{ $t("pages.home.header.title") }}
          </p>
          <p class="catch-subtitle pb-4 mb-0">
            {{ $t("pages.home.header.typed.intro") }}
            <textra
              class="textra"
              :data="typedStrings"
              filter="flip"
              :infinite="true"
              :timer="2"
            />
          </p>
        </div>
        <div
          v-if="displayContactForm"
          class="d-flex col-md-6 col-lg-4 mt-4"
        >
          <home-contact />
        </div>
        <div
          v-else
          class="d-none d-lg-block col-lg-4"
        >
          <div class="animation-wrapper">
            <div class="home-animation">
              <div class="animation-background"></div>
              <div class="flame"></div>
              <div class="outer-spinning-circle">
                <div class="inner-spinning-circle"></div>
              </div>
            </div>
            <div class="orbit">
              <div class="orbit-elements-wrapper">
                <div
                  v-for="element in orbitElements"
                  :key="element.class"
                  :class="['orbit-element', element.class]"
                >
                  <font-awesome-icon :icon="element.icon" size="2x" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <svg
        class="waves"
        preserveAspectRatio="none"
        shape-rendering="auto"
        viewBox="0 24 150 28"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
        </defs>
        <g class="parallax">
          <use
            fill="rgba(255,255,255,0.7"
            x="48"
            xlink:href="#gentle-wave"
            y="0"
          ></use>
          <use
            fill="rgba(255,255,255,0.5)"
            x="48"
            xlink:href="#gentle-wave"
            y="3"
          ></use>
          <use
            fill="rgba(255,255,255,0.3)"
            x="48"
            xlink:href="#gentle-wave"
            y="5"
          ></use>
          <use
            fill="#fff"
            x="48"
            xlink:href="#gentle-wave"
            y="7"
          ></use>
        </g>
      </svg>
    </div>

    <div class="quote-section inverted-quote-section quote">
      <div class="container main-quote text-center">
        <h1>
          {{ $t("pages.home.title") }}
        </h1>
      </div>
    </div>

    <image-block
      v-for="imageBlock in imageBlocks"
      :key="imageBlock.id"
      :block-class="imageBlock.class"
      :btn-class="imageBlock.btnClass"
      data-aos="fade-up"
      :extension="imageBlock.extension"
      :image-name="imageBlock.imageName"
      :image-on-right="imageBlock.imageOnRight"
      :link="imageBlock.link"
      :path="imageBlock.path"
      :src="imageBlock.src"
      :text="imageBlock.text"
      :title="imageBlock.title"
    />

    <div
      class="container-fluid py-3 bg-extra-light-grey"
      data-aos="fade-up"
    >
      <h2 class="text-center home-title mb-0 pb-2">
        {{ $t("pages.home.skills.title") }}
      </h2>
      <h3 class="text-center text-muted mb-5 pt-0">
        {{ $t("pages.home.skills.intro") }}
      </h3>
      <skills-cards />
    </div>

    <div
      class="container-fluid py-3"
      data-aos="fade-up"
    >
      <h2 class="text-center home-title mb-0 py-3 pb-md-2">
        {{ $t("pages.home.customers.title") }}
      </h2>
      <h3 class="text-center text-muted mb-5 pt-0">
        {{ $t("pages.home.customers.intro") }}
      </h3>
      <customers />
      <div class="container py-5 my-5 my-md-0 text-center">
        <router-link
          class="btn btn-orange py-3 font-weight-bold text-light rounded-pill"
          :to="{ name: 'triotech.front.project.index' }"
        >
          {{ $t("pages.home.customers.action") }}
        </router-link>
      </div>
    </div>

    <div class="container-fluid py-3 bg-extra-light-grey">
      <h2 class="text-center home-title mb-1">
        {{ $t("pages.home.technologies.title") }}
      </h2>
      <h3 class="text-center text-muted mb-5 pt-0">
        {{ $t("pages.home.technologies.intro") }}
      </h3>
      <framework-logos />
      <div class="container py-4 text-center">
        <a
          class="btn btn-orange py-3 font-weight-bold text-light rounded-pill"
          href="/pages/technologies-et-frameworks"
        >
          {{ $t("pages.home.technologies.action") }}
        </a>
      </div>
    </div>

    <div class="container-fluid py-3">
      <h2 class="text-center home-title mb-1">
        {{ $t("pages.home.news.title") }}
      </h2>
      <h3 class="text-center text-muted mb-5 pt-0">
        {{ $t("pages.home.news.intro") }}
      </h3>
      <news-cards :has-three="true" :is-home-page="true" />
      <div class="container py-4 text-center">
        <router-link
          class="btn btn-orange py-3 font-weight-bold text-light rounded-pill"
          :to="{ name: 'triotech.front.news.index' }"
        >
          {{ $t("pages.home.news.action") }}
        </router-link>
      </div>
    </div>
    <div class="ask-block-section mb-0">
      <ask-block />
    </div>
  </div>
</template>

<script>
  import AskBlock from '../components/AskBlock';
  import Customers from '../components/Customers';
  import FrameworkLogos from '../components/FrameworkLogos';
  import HomeContact from '../components/HomeContact';
  import ImageBlock from '../components/ImageBlock';
  import NewsCards from '../components/NewsCards';
  import SkillsCards from '../components/SkillsCards';

  export default {
    name: 'HomePage',
    components: {
      AskBlock,
      Customers,
      FrameworkLogos,
      HomeContact,
      ImageBlock,
      NewsCards,
      SkillsCards,
    },
    data() {
      return {
        orbitElements: [{
          class: 'company',
          icon: 'building',
        }, {
          class: 'cooperation',
          icon: 'handshake-angle',
        }, {
          class: 'database',
          icon: 'database',
        }, {
          class: 'network',
          icon: 'network-wired',
        }, {
          class: 'people',
          icon: 'users',
        }, {
          class: 'server',
          icon: 'server',
        }, {
          class: 'laptop',
          icon: 'laptop-code',
        }, {
          class: 'web',
          icon: 'globe',
        }],
        displayContactForm: false,
        imageBlocks: [{
          extension: 'jpg',
          imageName: 'business_meeting',
          link: {
            text: this.$t('pages.home.snippets.projects.action'),
            name: 'triotech.front.project.index',
          },
          path: '../../static/images/content_page/',
          src: '../../static/images/content_page/business_meeting.jpg',
          text: this.$t('pages.home.snippets.projects.text'),
          title: this.$t('pages.home.snippets.projects.title'),
        }, {
          class: 'bg-extra-light-grey',
          extension: 'jpg',
          imageName: 'design',
          imageOnRight: true,
          link: {
            text: this.$t('pages.home.snippets.design.action'),
            name: 'triotech.front.expertise.show',
            params: { slug: 'design-ui-ux' },
          },
          path: '../../static/images/content_page/',
          src: '../../static/images/content_page/design.jpg',
          text: this.$t('pages.home.snippets.design.text'),
          title: this.$t('pages.home.snippets.design.title'),
        }, {
          class: 'bg-primary text-light',
          extension: 'jpg',
          imageName: 'client_meeting',
          link: {
            text: this.$t('pages.home.snippets.expertise.action'),
            name: 'triotech.front.expertise',
          },
          path: '../../static/images/content_page/',
          src: '../../static/images/content_page/client_meeting.jpg',
          title: this.$t('pages.home.snippets.expertise.title'),
          text: this.$t('pages.home.snippets.expertise.text'),
          btnClass: 'btn-dark',
        }],
        typedStrings: [
          this.$t('pages.home.header.typed.1'),
          this.$t('pages.home.header.typed.2'),
          this.$t('pages.home.header.typed.3'),
          this.$t('pages.home.header.typed.4'),
          this.$t('pages.home.header.typed.5'),
        ],
      };
    },
  };
</script>
