<template>
  <div class="container mt-4">
    <div class="row">
      <div
        v-for="item in displayedNews"
        :key="item.id"
        class="col-md-6 col-lg-4 mb-4"
      >
        <NewsCard
          :has-three="hasThree"
          :item="item"
        />
      </div>
    </div>
    <b-pagination-nav
      v-if="!hasThree && 0 < pages"
      align="fill"
      class="mt-4"
      :link-gen="linkGen"
      :number-of-pages="pages"
      size="lg"
      use-router
    />
    <div v-if="isLoading" class="text-center loader">
      <i class="ti ti-2x ti-spin ti-refresh"></i>
    </div>
  </div>
</template>

<script>
  import NewsCard from './NewsCard';

  export default {
    name: 'NewsCards',
    components: {
      NewsCard,
    },
    props: {
      hasThree: {
        type: Boolean,
        default: false,
      },
      isHomePage: {
        type: Boolean,
        default: false,
      },
      news: {
        type: Array,
        default: () => [],
      },
      hasRelatedNews: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        fetchedNews: [],
        page: 1,
        pages: 0,
        isLoading: false,
      };
    },
    computed: {
      displayedNews() {
        if (0 < this.news.length) {
          return this.news;
        }

        return this.fetchedNews;
      },
    },
    beforeMount() {
      this.page = this.$route.query.page ? this.$route.query.page : 1;

      this.fetchNews();
    },
    mounted() {
      this.$bus.$on('news.fetch', this.fetchNews);
    },
    methods: {
      linkGen(pageNum) {
        return 1 === pageNum ? '?' : `?page=${pageNum}`
      },
      async fetchNews() {
        if (0 < this.news.length) {
          return;
        }

        if ((0 !== this.pages && this.page >= this.pages) || this.isLoading) {
          return;
        }

        this.isLoading = true;

        this.$ajax.get(`public/news/?embed=1&limit=${this.hasThree ? '3' : `12&page=${this.page}`}`)
          .then((data) => {
            if ('pages' in data) {
              this.pages = data.pages;

              this.$set(this, 'fetchedNews', this.fetchedNews.concat(data._embedded.items));
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
    },
  };
</script>
