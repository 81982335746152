var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'card', 'card-scale-animation', 'shadow', {
      'h-100' : _vm.hasThree,
    }]},[_c('ResponsivePicture',{attrs:{"alt":_vm.item.thumbnail_image_alt,"css-class":"card-img-top","filter-name":"news_thumbnail","src":_vm.item.thumbnail_image}}),_vm._v(" "),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card-body-layout"},[_c('router-link',{staticClass:"stretched-link",attrs:{"to":{
          name: 'triotech.front.news.show',
          params: {
            slug: _vm.item.slug,
          },
        }}},[_c('h3',{staticClass:"card-title font-weight-bold"},[_vm._v("\n          "+_vm._s(_vm.item.label)+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"card-text small text-muted d-none d-lg-block",domProps:{"innerHTML":_vm._s(_vm.item.short_content)}}),_vm._v(" "),_c('div',{staticClass:"pt-2 text-primary small float-right font-weight-bold align-self-end"},[_vm._v("\n        "+_vm._s(_vm._.date(_vm.item.published_at))+"\n      ")])],1)])],1)}
var staticRenderFns = []
export { render, staticRenderFns }