<template>
  <div
    :class="[
      'card', 'card-scale-animation', 'shadow', {
        'h-100' : hasThree,
      }]"
  >
    <ResponsivePicture
      :alt="item.thumbnail_image_alt"
      css-class="card-img-top"
      filter-name="news_thumbnail"
      :src="item.thumbnail_image"
    />
    <div class="card-body">
      <div class="card-body-layout">
        <router-link
          class="stretched-link"
          :to="{
            name: 'triotech.front.news.show',
            params: {
              slug: item.slug,
            },
          }"
        >
          <h3 class="card-title font-weight-bold">
            {{ item.label }}
          </h3>
        </router-link>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="card-text small text-muted d-none d-lg-block" v-html="item.short_content"></div>
        <div class="pt-2 text-primary small float-right font-weight-bold align-self-end">
          {{ _.date(item.published_at) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ResponsivePicture from './ResponsivePicture';

  export default {
    components: {
      ResponsivePicture,
    },
    props: {
      hasThree: {
        type: Boolean,
        default: false,
      },
      item: {
        type: Object,
        default() {
          return {};
        },
      },
    },
  };
</script>
